<template>
    <div class="instructions-container">
        <div :class="['instructions-card', 'container', containerSize === 'small' ? 'instructions-card__container' : '']">
            <slot>
            </slot>
        </div>
    </div>
</template>

<script scoped>
export default {
  name: 'InstructionsCard',
  props: {
      containerSize: {
          type: String,
          validator: val => ['small', 'fluid'].includes(val),
          default: 'fluid'
      }
  }
}

</script>

<style lang="scss" scoped>

.instructions-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.instructions-card {
    padding: 48px $InstructionCardPadding;
    background-color: white;
    border-radius: $cardRadius;
    transform: translate(0, -25%);
    &__container {
        max-width: 1000px !important;
    }
}


</style>