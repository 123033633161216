<template>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
                <CardTitle class="modal" :text="header" />
            </div>

            <div class="modal-body">
              <slot></slot>
            </div>

            <div class="modal-footer columns">
                <div class="column is-2"></div>
                <!-- <BigRoundedButton @click.native="$emit('close')" class="column column-button">{{ confirmText }}</BigRoundedButton> -->
                <o-button 
                  type="primary" 
                  class="o-btn--primary column-button" 
                  :label="confirmText"
                  @click.native="$emit('close')">
                </o-button>
                <div class="column is-2"></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
</template>

<script>

import CardTitle from "@/components/CardTitle.vue";
import oButton from '@/objects/o-button/o-button.vue';

export default {
  name: "Modal",
  components: {
    CardTitle,
    'o-button': oButton
  },
  props: {
      header: {
        type: String,
        required: true
      },
      confirmText: {
        type: String,
        required: true
      }
  }
};

</script>

<style scoped>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .75);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  margin: 0px auto;
  padding: 64px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
    font-family: 'Colfax-Regular';
    font-size: 20px;
    margin: 32px 0 0 0;
    text-align: left;
}

.modal-default-button {
  float: right;
}

.column-button a {
    color: white;
}

</style>

<style>

.modal-header .instructions-card-title-container p {
    font-size: 32px;
    padding-left: 1rem;
    padding-right: 1rem;
    width: fit-content;
    margin: auto;
}

.modal-footer .confirm-button {
    position: relative;
    bottom: 0rem;
}

.modal-footer div {
    height: 0px;
}

.modal-footer .cancel-button p {
  background-color: #003865;
}

.modal-footer {
  z-index: 2;
  position: relative;
  float: left;
  width: 100%;
  padding-top: 20px;
}

.modal-footer button {
  margin: 0 auto;
}

</style>