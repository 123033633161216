<template>
    <div class="instructions-card-title-container">
        <p>{{ text.toUpperCase()}}</p>
    </div>
</template>

<script scoped>

export default {
  name: 'CardTitle',
  props: {
      text: {
          type: String,
          required: true
      }
  }
}

</script>

<style scoped>

.instructions-card-title-container {
    position: absolute!important;
    z-index: 1000;
    left: 0;
    width: 100% !important;
    text-align: center;
    -webkit-box-flex: none;
    flex: none;
}

.instructions-card-title-container p {
    background-color: black;
    color: white;
    font-family: "Colfax-Black";
    font-size: 48px;
    display: inline-block;
    padding: 0px 30px;
}

.choose-background.instructions-card-title-container {
    margin-top: 294px;
}
.watch-video.instructions-card-title-container {
    margin-top: -90px;
}
.share-vlog.instructions-card-title-container {
    margin-top: -130px;
}
.modal.instructions-card-title-container {
    position: relative;
}
.share.instructions-card-title-container {
    margin-top: -90px;
}
.intro.instructions-card-title-container {
    margin-top: -94px;
}
.memories.instructions-card-title-container {
    margin-top: -12vh;
}


</style>
