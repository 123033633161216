export default {
  name: 'oPreloader',

  props: {
    color: {
      type: String,
      default: 'black'
    }
  }
}
