<template>
  <div class="container is-fluid sharing-container">
    <Modal
      v-if="showModal" @close="showModal = false"
      :header="getEmsDataPropertyText('qr_popup_header_page4')"
      :confirmText="getEmsDataPropertyText('video_sent_modal_done_button_page5')"
      confirmLink="/">
      <div class="columns">
        <div class="column qr-column is-4">
          <qrcode-vue :size="196" :value="this.uploaded_video_url" />
        </div>
        <div class="column qr-description">
          <p>{{ uploaded_video_url }}</p>
        </div>
      </div>
    </Modal>

    <InstructionsCard>
      <div class="row card-title-container">
        <CardTitle class="share-vlog" :text="content.share_title" />
      </div>

      <div class="row">
        <div class="column first">
          <div class="row">
            <img class="share-icon" src="../assets/img/Screen.svg" />
          </div>
          <div class="row">
            <h3 class="sharing-subtitle">{{ content.share_link_title }}</h3>
          </div>
          <div class="row sharing-description-container">
            <p class="sharing-description">{{ content.share_link_description }}</p>
          </div>
        </div>
        <div class="column">
          <div class="row">
            <img class="share-icon sms-icon" src="../assets/img/Iphone.svg" />
          </div>
          <div class="row">
            <h3 class="sharing-subtitle">{{ content.share_sms_title }}</h3>
          </div>
          <div class="row sharing-description-container">
            <p class="sharing-description">{{ content.share_sms_description }}</p>
          </div>
        </div>
        <div class="column third">
          <div class="row">
            <img class="share-icon email-icon" src="../assets/img/Mail.svg" />
          </div>
          <div class="row">
            <h3 class="sharing-subtitle">{{ content.share_email_title }}</h3>
          </div>
          <div class="row sharing-description-container">
            <p class="sharing-description">{{ content.share_email_description }}</p>
          </div>
        </div>
      </div>
      <div class="row button-row">
        <!--
        <BigRoundedButton @click.native="showModal = true" class="column column-button">{{ getEmsDataPropertyText('show_link_page4') }}</BigRoundedButton>
        <BigRoundedButton @click.native="showShareInput('sms')" class="column column-button">{{ getEmsDataPropertyText('send_by_sms_page4') }}</BigRoundedButton>
        <BigRoundedButton @click.native="showShareInput('email')" class="column column-button">{{ getEmsDataPropertyText('send_by_email_page4') }}</BigRoundedButton>
        -->
        <div class="button-row__column">
          <o-button 
            type="primary" 
            class="o-btn--primary column-button" 
            :label="content.share_link_button_label"
            @click.native="showModal = true">
          </o-button>
        </div>
        <div class="button-row__column">
          <o-button 
            type="primary" 
            class="o-btn--primary column-button" 
            :label="content.share_sms_button_label"
            @click.native="showShareInput('sms')">
          </o-button>
        </div>
        <div class="button-row__column">
          <o-button 
            type="primary" 
            class="o-btn--primary column-button" 
            :label="content.share_email_button_label"
            @click.native="showShareInput('email')">
          </o-button>
        </div>
      </div>
    </InstructionsCard>

    <div class="privacy-info">
      <p>{{ content.share_terms }}</p>
    </div>
  </div>
</template>

<script>
import CONST from '@/utils/Constants'
import ApiHelper from "@/helpers/ApiHelper";
import ErrorHelper from "@/helpers/ErrorHelper";
// @ is an alias to /src
import QrcodeVue from 'qrcode.vue'
import Modal from "@/components/Modal.vue";
import CardTitle from "@/components/CardTitle.vue";
import InstructionsCard from "@/components/InstructionsCard.vue";
import oButton from '@/objects/o-button/o-button.vue';

import { mapState } from "vuex";

export default {
  name: "intro",
  components: {
    Modal,
    QrcodeVue,
    CardTitle,
    InstructionsCard,
    'o-button': oButton
  },
  data: () => ({
    showModal: false
  }),
  mounted() {
    let data = {
        "message": this.getEmsDataPropertyText('global_background_image'),
        "channel": CONST.PUSHER.CHANNEL,
        "event-name": CONST.EVENT.BACKGROUND.SELECTED,
      }

      ApiHelper.post(CONST.API.BACKGROUND.SELECT, data, {headersType: 'basic'})
      .catch(error => {
          ErrorHelper.catch(error, this);
      });
  },
  computed: {
    ...mapState(["uploaded_video_url"]),
    content() {
      return this.$store.getters.content;
    }
  },
  methods: {
    /**
     * This method pushes the input contact details
     * view on top of the stack. The shareViewType argument
     * indicated which sharing technology is to be used (sms, email)
     */
    showShareInput: function (shareViewType) {
      this.$router.push({
        name: "input-contact-details",
        params: {
          videoURL: this.uploaded_video_url,
          shareViewType: shareViewType
        }
      });
    }
  }
};
</script>

<style scoped>

.column {
  float: left;
  width: 33.33%;
}

.button-row__column {
  width: 33%;
  float: left;
}

.sms-icon {
  position: relative;
  left: 25px;
}

.email-icon {
  position: relative;
  left: -12px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.column.first {
  border-right: 2px solid;
}
.column.third {
  border-left: 2px solid;
}

.buttons {
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 20%;
  width: fit-content;
}

.button-row {
  bottom: -3rem;
  position: relative;
}

.material-icons {
  float: left;
}

.privacy-info {
  width: 35vw;
  text-align: right;
  position: absolute;
  right: 64px;
  bottom: 64px;
  color: white;
  font-family: 'Silka-Regular';
}

.privacy-info a {
  color: white;
  text-decoration: underline;
}

.share-icon {
  width: 200px;
  height: 200px;
}

.sharing-subtitle {
  font-family: 'Colfax-Bold';
  font-size: 32px;
}

.sharing-description {
  font-family: 'Colfax-Regular';
  font-size: 18px;
}

.sharing-description-container {
  padding-left: 32px;
  padding-right: 32px;
}

.qr-column {
  text-align: center;
}

.qr-description {
  width: auto;
}

</style>

<style>

/*
 * Override the container card lateral padding in
 * order to make all the three columns symmetric.
 *
 * The container class is used only to override the
 * component's scoped styles
 */
.sharing-container .instructions-card {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-top: 96px;
  margin-top: 260px;
}

</style>