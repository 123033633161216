import oPreloader from '@/objects/o-preloader/o-preloader.vue';

export default {
  name: 'oButton',

  components: {
    'o-preloader': oPreloader
  },

  props: {
    label: String,
    type: {
      type: String,
      default: 'button'
    },
    icon: String,
    iconPosition: String
  }
};
